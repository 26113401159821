@import 'src/design-system/styles/typography.mixins';

.sectionDivider:not(:last-child) {
  position: relative;
  margin-bottom: 64px;

  &::after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    bottom: -32px;
    height: 1px;
    width: 100%;
    box-shadow: inset 0 -1px 0 var(--shape-50);
  }
}

.title {
  @include font-style-subtitle($font-weight: $font-weight-bold);
  font-family: inherit;
  color: var(--text-20);
  margin: 0 0 32px 0;
}
